//THIS IS A GENERATED FILE, MODIFY BY HAND WHEN REMOVING FEATURE TOGGLES - see README.md

export enum Feature {
  UseTVDeploymentForChromecast = 'useTVDeploymentForChromecast',
  Disableacceptterms = 'disableacceptterms',
  PrivacyPolicyAcknowledgement = 'privacyPolicyAcknowledgement',
  RedirectToDigitalCheckout = 'redirectToDigitalCheckout',
  Sharedbuttermilk = 'sharedbuttermilk',
  DisableActivityFeedModal = 'disableActivityFeedModal',
  Enablefrcalanguage = 'enablefrcalanguage',
  Strava_account_linking = 'strava_account_linking',
  Completiontime = 'completiontime',
  Homepage_optimizations = 'homepage_optimizations',
  Navupdates = 'navupdates',
  Stickydogcycle = 'stickydogcycle',
  Vppa = 'vppa',
  Previewclassmodal = 'previewclassmodal',
  Onboarding_quiz = 'onboarding_quiz',
  IsProspectsGuestPassEnabled = 'isProspectsGuestPassEnabled',
  Matcha_referral_pulse = 'matcha_referral_pulse',
  Simple_goals = 'simple_goals',
  FreeSnowDen = 'freeSnowDen',
  Early_exit_other_reason_form = 'early_exit_other_reason_form',
  Usercaptionlocales = 'usercaptionlocales',
  Simple_goals_pcr = 'simple_goals_pcr',
  Private_profile__preference_update = 'private_profile__preference_update',
  Bookmark_page = 'bookmark_page',
  Search_query_carousel = 'search_query_carousel',
  Important_stuff = 'important_stuff',
  End_of_important_stuff = 'end_of_important_stuff',
  Onetrust_link_updates = 'onetrust_link_updates',
  BFCM_2024_referral_pulse = 'bfcm_2024_referral_pulse',
  Ny_app_promo = 'ny_app_promo',
  Deprecate_programs = 'deprecate_programs',
  Deprecate_programs_blackout_period = 'deprecate_programs_blackout_period',
}

export const variables = {
  [Feature.UseTVDeploymentForChromecast]: [
    { key: 'PROD_applicationId', type: 'string' },
    { key: 'UAT_applicationId', type: 'string' },
  ],
  [Feature.Strava_account_linking]: [{ key: 'TLD_Support', type: 'boolean' }],
  [Feature.Completiontime]: [{ key: 'time', type: 'integer' }],
  [Feature.Stickydogcycle]: [{ key: 'trialLengthInDays', type: 'integer' }],
  [Feature.FreeSnowDen]: [
    { key: 'prefilledSubregion', type: 'string' },
    { key: 'isUnderage', type: 'boolean' },
    { key: 'shouldShowPrompt', type: 'boolean' },
    { key: 'prefilledCountry', type: 'string' },
    { key: 'isSecondPrompt', type: 'boolean' },
    { key: 'overrideData', type: 'boolean' },
    { key: 'isNewUser', type: 'boolean' },
  ],
} as const;

export const experiments = {} as const;
